import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { About } from "./components/About";
// import { Subscribe } from "./components/Subscribe";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
// import { Audio } from "./components/Audio";
import { Footer } from "./components/Footer";
import { useEffect } from 'react'; // Import useEffect
// import logo from './assets/img/logo1.png'; // Import the logo from src


const App = () => (
    <div className="App">
      <NavBar />
      <Banner />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );


export default App;
