import { useState, useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaPlay, FaPause, FaShareAlt } from "react-icons/fa";

export const ProjectCard = ({ title, description, imgUrl, audioUrl, audioDescription, dialCode }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(audioUrl));

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleProgressClick = (event) => {
    const progressBar = event.target;
    const newTime = (event.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: `Check out this audio: ${audioDescription}`,
        url: audioUrl,
      })
      .then(() => console.log("Audio shared successfully!"))
      .catch((error) => console.error("Error sharing audio:", error));
    } else {
      alert("Sharing is not supported on this device. Please copy the link manually.");
    }
  };

  return (
    <Col lg={4} md={6} sm={12} xs={12} className="proj-col">
      <div className="proj-imgbx">
        <div className="img-wrapper">
          <img src={imgUrl} alt={title} />
        </div>
        <div className="play-btn" onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </div>
        <div className="audio-controls">
          <div className="audio-time current-time">{formatTime(currentTime)}</div>
          <div className="audio-progress" onClick={handleProgressClick}>
            <div className="progress-bar" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
          </div>
          <div className="audio-time duration">{formatTime(duration)}</div>
        </div>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      <div className="proj-extra">
        <a 
          href={`tel:${encodeURIComponent(dialCode)}`} 
          className="subscribe-btn"
        >
          <Button variant="success">Subscribe</Button> {/* Changed to green */}
        </a>

        <Button 
          variant="dark" 
          className="share-btn" 
          onClick={handleShare}
          style={{ width: '50%' }} // Reduced width
        >
          <FaShareAlt /> Share
        </Button>
      </div>
      <p className="audio-description">{audioDescription}</p>

      <style jsx>{`
        .proj-imgbx {
          position: relative;
          border-radius: 22px;
          overflow: hidden;
          height: auto;
        }

        .img-wrapper img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .play-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.5);
          padding: 15px;
          cursor: pointer;
          color: white;
          font-size: 24px;
        }

        .audio-controls {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          bottom: 40px;
          width: 90%;
          left: 5%;
          margin-top: 10px;
        }

        .audio-progress {
          flex-grow: 1;
          height: 10px;
          background: rgba(255, 255, 255, 0.5);
          margin: 0 10px;
          cursor: pointer;
          position: relative;
        }

        .progress-bar {
          height: 100%;
          background: #007bff;
        }

        .proj-txtx {
          padding: 20px;
          color: white;
          text-align: center;
        }

        .proj-extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          margin-top: 5px;
        }

        .subscribe-btn {
          text-decoration: none;
          flex: 0 0 45%;
        }

        .share-btn {
          text-decoration: none;
          flex: 0 0 45%;
          
        }

        .audio-description {
          font-size: 14px;
          color: white;
          margin-top: 5px;
          text-align: center;
        }

        /* Responsive styles */
        @media (max-width: 930px) {
          .proj-col {
            flex: 0 0 50%; /* 2 cards per row */
            max-width: 50%;
          }
        }

        @media (max-width: 768px) {
          .proj-extra {
            gap: 5px;
          }

          .subscribe-btn, .share-btn {
            flex: 0 0 48%; /* Adjust buttons size for smaller screens */
          }

          .audio-description {
            margin-top: 5px;
            font-size: 13px;
          }
        }

        @media (max-width: 540px) {
          .proj-col {
            flex: 0 0 100%; /* 1 card per row */
            max-width: 100%;
          }

          .proj-extra {
            padding: 5px 5px;
            gap: 5px;
          }

          .subscribe-btn, .share-btn {
            flex: 0 0 48%; /* Same size for both buttons */
            text-align: center;
          }

          .audio-description {
            font-size: 12px;
            margin-top: 3px;
          }
        }
      `}</style>
    </Col>
  );
};
