import { useState } from "react";
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import TrackVisibility from 'react-on-screen';
import colorSharp2 from "../assets/img/color-sharp2.png";

import projImg1 from "../assets/img/maher3.jpeg";
import projImg2 from "../assets/img/sudais3.jpeg";
import projImg3 from "../assets/img/idris3.jpeg";
import projImg4 from "../assets/img/djabouti..webp";
import audio1 from "../assets/img/Audio1.mp4";
import audio2 from "../assets/img/Audio2.mp4";
import audio3 from "../assets/img/Audio3.mp4";
import audio4 from "../assets/img/Audio4.mp4";
import audio5 from "../assets/img/Audio5.mp4";
import audio6 from "../assets/img/Audio6.mp4";
import audio7 from "../assets/img/Audio7.mp4";
import audio8 from "../assets/img/Audio8.mp4";
import audio9 from "../assets/img/Audio9.mp4";
import audio10 from "../assets/img/Audio10.mp4";
import audio11 from "../assets/img/Audio11.mp4";
import audio12 from "../assets/img/Audio12.wav";
import audio13 from "../assets/img/Audio13.wav";
import audio14 from "../assets/img/Audio14.wav";
import audio15 from "../assets/img/Audio15.wav";
import audio16 from "../assets/img/Audio16.wav";
import audio17 from "../assets/img/Audio17.wav";
import audio18 from "../assets/img/Audio18.wav";
import audio19 from "../assets/img/Audio19.wav";
import audio20 from "../assets/img/Audio20.wav";
import audio21 from "../assets/img/Audio21.wav";
import audio22 from "../assets/img/Audio22.wav";
import audio23 from "../assets/img/Audio23.wav";
import audio24 from "../assets/img/Audio24.wav";
import audio25 from "../assets/img/Audio25.wav";
import audio26 from "../assets/img/Audio26.wav";
import audio27 from "../assets/img/Audio27.wav";
import audio28 from "../assets/img/Audio28.wav";
import audio29 from "../assets/img/Audio29.wav";
import audio30 from "../assets/img/Audio30.mp3";
import audio31 from "../assets/img/Audio31.mp3";




export const Projects = () => {
  const itemsPerRow = 3; // Number of items per row
  
  // Define separate arrays for each tab
  const quranRecitations = [
    { imgUrl: projImg4, audioUrl: audio1, audioDescription: "Sheikh Djabouti - Dial *812*862#", dialCode: "*812*862#" },
    { imgUrl: projImg4, audioUrl: audio2, audioDescription: "Sheikh Djabouti - Dial *812*863#", dialCode: "*812*863#" },
    { imgUrl: projImg4, audioUrl: audio3, audioDescription: "Sheikh Djabouti - Dial *812*863#", dialCode: "*812*863#" },
    { imgUrl: projImg4, audioUrl: audio7, audioDescription: "Sheikh Djabouti - Dial *812*868#", dialCode: "*812*868#" },
    { imgUrl: projImg4, audioUrl: audio4, audioDescription: "Sheikh Djabouti - Dial *812*865#", dialCode: "*812*865#" },
    { imgUrl: projImg4, audioUrl: audio12, audioDescription: "Sheikh Djabouti - Dial *812*872#", dialCode: "*812*872#" },
    { imgUrl: projImg4, audioUrl: audio14, audioDescription: "Sheikh Djabouti - Dial *812*874#", dialCode: "*812*874#" },
    { imgUrl: projImg4, audioUrl: audio15, audioDescription: "Sheikh Djabouti - Dial *812*875#", dialCode: "*812*875#" },
    { imgUrl: projImg4, audioUrl: audio16, audioDescription: "Sheikh Djabouti - Dial *812*876#", dialCode: "*812*8726#" },
    { imgUrl: projImg4, audioUrl: audio17, audioDescription: "Sheikh Djabouti - Dial *812*877#", dialCode: "*812*877#" },
    { imgUrl: projImg4, audioUrl: audio18, audioDescription: "Sheikh Djabouti - Dial *812*878#", dialCode: "*812*878#" },

  ];

  const islamicNasheeds = [
    { imgUrl: projImg1, audioUrl: audio5, audioDescription: "Maher Zain - Dial *812*866#", dialCode: "*812*866#" },
    { imgUrl: projImg1, audioUrl: audio6, audioDescription: "Maher Zain - Dial *812*867#", dialCode: "*812*867#" },
    { imgUrl: projImg1, audioUrl: audio11, audioDescription: "Maher Zain - Dial *812*872#", dialCode: "*812*872#" },
    { imgUrl: projImg1, audioUrl: audio13, audioDescription: "Maher Zain - Dial *812*873#", dialCode: "*812*873#" },
    { imgUrl: projImg1, audioUrl: audio30, audioDescription: "Maher Zain - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg1, audioUrl: audio31, audioDescription: "Maher Zain - Dial *812*---#", dialCode: "*812*---#" },

  ];

  const duaRecitations = [
    { imgUrl: projImg2, audioUrl: audio8, audioDescription: "Sheikh Sudais - Dial *812*869#", dialCode: "*811*869#" },
    { imgUrl: projImg2, audioUrl: audio9, audioDescription: "Sheikh Sudais - Dial *812*870#", dialCode: "*812*870#" },
    { imgUrl: projImg2, audioUrl: audio10, audioDescription: "Sheikh Sudais - Dial *812*871#", dialCode: "*812*871#" },
    { imgUrl: projImg2, audioUrl: audio19, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio20, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio21, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio22, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio23, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio24, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio25, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio26, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio27, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio28, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },
    { imgUrl: projImg2, audioUrl: audio29, audioDescription: "Sheikh Sudais - Dial *812*---#", dialCode: "*812*---#" },

  ];

  // Define independent states for each tab
  const [visibleQuranRecitations, setVisibleQuranRecitations] = useState(itemsPerRow);
  const [visibleIslamicNasheeds, setVisibleIslamicNasheeds] = useState(itemsPerRow);
  const [visibleDuaRecitations, setVisibleDuaRecitations] = useState(itemsPerRow);

  // Load More functions for each tab
  const loadMoreQuranRecitations = () => setVisibleQuranRecitations(prev => prev + itemsPerRow);
  const loadMoreIslamicNasheeds = () => setVisibleIslamicNasheeds(prev => prev + itemsPerRow);
  const loadMoreDuaRecitations = () => setVisibleDuaRecitations(prev => prev + itemsPerRow);

  return (
    <section className="project" id="projects">
      <Container>
        <Row className="project-row">
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>OUR PLAYLIST</h2>
                  <p>Explore our curated playlist featuring the serene beauty of Quran recitations, the uplifting melodies of nasheeds, and the heartfelt sincerity of duas. Discover moments of peace, by Clicking the Download Icon to Subscribe.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Quran Recitations</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Islamic Nasheeds</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Dua Recitations</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row className="project-row">
                          {quranRecitations.slice(0, visibleQuranRecitations).map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                        {visibleQuranRecitations < quranRecitations.length && (
                          <div className="text-center mt-4">
                            <Button variant="success" onClick={loadMoreQuranRecitations}>View More</Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row className="project-row">
                          {islamicNasheeds.slice(0, visibleIslamicNasheeds).map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                        {visibleIslamicNasheeds < islamicNasheeds.length && (
                          <div className="text-center mt-4">
                            <Button variant="success" onClick={loadMoreIslamicNasheeds}>View More</Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row className="project-row">
                          {duaRecitations.slice(0, visibleDuaRecitations).map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                        {visibleDuaRecitations < duaRecitations.length && (
                        <div className="text-center mt-4">
                          <Button variant="success" onClick={loadMoreDuaRecitations}>View More</Button> {/* Changed to green */}
                        </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
